.sd-paneldynamic {
    .sd-progress {
        position: absolute;
        left: 0;
        right: 0;
        height: 2px;
        z-index: 2;
        transform: translateY(-1px);
    }

    & > .sd-panel {
        padding-top: 1px;
        padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
    }

    & .sd-paneldynamic__panel-wrapper > .sd-panel > .sd-panel__header {
        padding-bottom: 0;

        &:after {
            display: none;
        }

        padding-top: var(--sd-base-vertical-padding);

        & > .sd-panel__title {
            color: $foreground-light;
        }
    }
}

.sd-question--readonly,
.sd-question--preview {
    .sd-paneldynamic .sd-question__placeholder {
        color: $foreground;
    }
}
.sd-paneldynamic__separator {
    display: none;
}
.sd-paneldynamic__panel-wrapper {
    box-sizing: border-box;
    padding-bottom: calc(1 * var(--sd-base-padding));
}

.sd-paneldynamic__panel-wrapper:after {
    display: table;
    clear: both;
    content: " ";
}

.sd-paneldynamic__footer .sd-paneldynamic__separator,
.sd-paneldynamic__panel-wrapper--list ~ .sd-paneldynamic__panel-wrapper--list:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0;
    border-color: $border-light;
    background: $border-light;
    height: 1px;
    border: none;
}
.sd-paneldynamic__separator:only-child {
    display: none;
}

.sd-paneldynamic__panel-wrapper--in-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sd-paneldynamic__footer {
    clear: both;

    .sd-paneldynamic__prev-btn,
    .sd-paneldynamic__next-btn {
        display: block;

        svg {
            width: calcSize(2);
            height: calcSize(2);
        }
    }

    .sd-paneldynamic__add-btn,
    .sd-paneldynamic__progress-text,
    .sd-paneldynamic__progress--bottom {
        display: initial;
    }
}

.sd-paneldynamic__buttons-container {
    display: flex;
    align-items: center;
    padding: calc(var(--sd-base-vertical-padding) - #{$base-unit}) 0;
}

.sd-paneldynamic__progress-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: calcSize(-1);
}

.sd-paneldynamic__progress-text {
    font-size: $font-questiontitle-size;
    line-height: multiply(1.5, $font-questiontitle-size);
    color: $font-questiondescription-color;
    margin: 0;
    margin-right: calcSize(3);
}

.sd-paneldynamic__prev-btn,
.sd-paneldynamic__next-btn {
    width: calcSize(2);
    height: calcSize(2);

    .sv-svg-icon {
        display: block;
    }
}

.sd-paneldynamic__prev-btn {
    margin-right: calcSize(2);
}

.sd-paneldynamic__next-btn {
    margin-left: calcSize(-1);
    margin-right: calcSize(2);
    transform: rotate(180deg);
}

.sd-paneldynamic__placeholder {
    .sd-paneldynamic__add-btn {
        display: initial;
        margin-left: 0;
    }
}

.sd-question--empty.sd-question--paneldynamic {
    & > .sd-question__content {
        padding-bottom: var(--sd-base-padding);
        --animation-padding-bottom: var(--sd-base-padding);
    }
}

.sd-question--paneldynamic:not(.sd-question--empty) {
    & > .sd-question__content {
        & > .sd-question__comment-area {
            padding-bottom: var(--sd-base-padding);
        }
    }
}

.sd-paneldynamic__buttons-container .sd-action-bar {
    width: 100%;
    margin: 0 calcSize(-3);
    width: calc(100% + 6 * #{$base-unit});
}

.sd-paneldynamic__panel-footer {
    border-top: none;
    position: relative;
    top: calc(0.5 * var(--sd-base-vertical-padding));
    margin-right: calcSize(-3);
    justify-content: flex-end;
}

.sd-paneldynamic__panel-footer {
    .sv-action:not(.sv-action--hidden) {
        margin-bottom: calcSize(-1);
    }
}

.sd-tabs-toolbar.sv-action-bar {
    align-items: flex-start;
    margin: calcSize(1) 0 -1px calcSize(-2);
    width: calc(100% + 4 * #{$base-unit});
    z-index: 1;
}

.sd-tabs-toolbar.sd-tabs-toolbar--left {
    justify-content: flex-start;
}

.sd-tabs-toolbar.sd-tabs-toolbar--right {
    justify-content: flex-end;
}

.sd-tabs-toolbar.sd-tabs-toolbar--center {
    justify-content: center;
}

.sd-tab-item {
    margin: 0 calcSize(2);
}

.sd-tab-item {
    @include useEditorFontSize;
    padding: calcSize(1) 0 calcSize(2);
    display: flex;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: flex;
    box-sizing: border-box;
    border: none;
    background-color: transparent;
    cursor: pointer;

    font-family: $font-family;
    font-size: $font-editorfont-size;
    line-height: multiply(1.5, $font-editorfont-size);
    color: $font-questiondescription-color;
    overflow-x: hidden;
    white-space: nowrap;
    box-shadow: inset 0px 0px 0px $primary;
    transition: box-shadow $transition-duration;

    &:hover,
    &:focus-visible {
        outline: none;
        box-shadow: inset 0px -2px 0px $primary;
    }
}

.sd-tab-item.sd-tab-item--pressed {
    color: $font-questiontitle-color;
    box-shadow: inset 0px -2px 0px $primary;
}

.sd-tab-item--icon {
    padding: calcSize(1);
    width: auto;
    border-radius: calcSize(12.5);

    use {
        fill: $foreground-light;
        opacity: 0.5;
    }
}

.sd-tab-item--icon:hover,
.sd-tab-item--icon.sd-tab-item--pressed {
    background-color: $primary-light;
    box-shadow: none;

    use {
        fill: $primary;
    }
}

.sd-tab-item--icon:hover {
    use {
        opacity: 1;
    }
}

.sd-tabs-toolbar .sv-dots {
    width: auto;
}

.sd-tabs-toolbar .sv-dots.sv-action--hidden {
    width: 0;
}

.sd-tab-item__title {
    line-height: calcLineHeight(1.5);
    height: calcSize(3);
    display: flex;
    align-items: center;
}

.sd-question__title ~ .sd-tabs-toolbar {
    margin-top: calcSize(3);
}

.sd-paneldynamic__header.sd-element__header.sd-paneldynamic__header-tab {
    padding-bottom: 0;
}

.sd-paneldynamic__header.sd-element__header.sd-paneldynamic__header-tab::after {
    bottom: 0;
}

.sd-question--paneldynamic.sd-element--with-frame {
    padding-bottom: 0;
}

.sd-paneldynamic__panels-container {
    position: relative;
    overflow: hidden;
    margin-left: calc(-1 * var(--sd-base-padding));
    padding-left: var(--sd-base-padding);
    margin-right: calc(-1 * var(--sd-base-padding));
    padding-right: var(--sd-base-padding);
}
.sd-paneldynamic__panel-wrapper {
    box-sizing: border-box;
}

@keyframes movePanel {
    from {
        transform: translateX(#{$pd-tab-move-margin});
    }
    to {
        transform: translateX(0);
    }
}

.sd-paneldynamic__panel-wrapper--fade-in-left,
.sd-paneldynamic__panel-wrapper--fade-in-right {
    animation-name: movePanel, changeHeight, paddingFadeIn, fadeIn;
    animation-duration: $pd-tab-move-in-duration, $pd-tab-height-change-duration, $pd-tab-height-change-duration,
        $pd-tab-fade-in-duration;
    animation-delay: $pd-tab-move-in-delay, $pd-tab-height-change-delay, $pd-tab-height-change-delay,
        $pd-tab-fade-in-delay;
    animation-timing-function: $ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
    padding-bottom: 0;
    transform: translateX(#{$pd-tab-move-margin});
    height: var(--animation-height-from);
    --animation-padding-top: 0;
    --animation-padding-bottom: calc(1 * var(--sd-base-padding));
}
.sd-paneldynamic__panel-wrapper--fade-in-left {
    --sjs-pd-tab-move-margin: calc(1 * #{$pd-tab-move-in-margin});
}
.sd-paneldynamic__panel-wrapper--fade-in-right {
    --sjs-pd-tab-move-margin: calc(-1 * #{$pd-tab-move-in-margin});
}

.sd-paneldynamic__panel-wrapper--fade-out-left,
.sd-paneldynamic__panel-wrapper--fade-out-right {
    animation-name: fadeIn, movePanel;
    animation-duration: $pd-tab-fade-out-duration, $pd-tab-move-out-duration;
    animation-delay: $pd-tab-fade-out-delay, $pd-tab-move-out-delay;
    animation-timing-function: $reverse-ease-out;
    animation-direction: reverse;
    animation-fill-mode: forwards;
    position: absolute;
    left: var(--sd-base-padding);
    top: 0;
    width: calc(100% - 2 * var(--sd-base-padding));
}
.sd-paneldynamic__panel-wrapper--fade-out-left {
    --sjs-pd-tab-move-margin: calc(-1 * #{$pd-tab-move-out-margin});
}
.sd-paneldynamic__panel-wrapper--fade-out-right {
    --sjs-pd-tab-move-margin: calc(1 * #{$pd-tab-move-out-margin});
}

.sd-paneldynamic__panel-wrapper--fade-in,
.sd-paneldynamic__panel-wrapper--fade-out {
    animation-name: fadeIn, moveInWithOverflow, paddingFadeIn;
    animation-fill-mode: forwards;
    --animation-padding-top: 0;
    --animation-padding-bottom: calc(1 * var(--sd-base-padding));
    min-height: 0 !important;
}
.sd-paneldynamic__panel-wrapper--fade-in {
    opacity: 0;
    animation-timing-function: $ease-out;
    animation-duration: $pd-list-fade-in-duration, $pd-list-move-in-duration, $pd-list-move-in-duration;
    animation-delay: $pd-list-fade-in-delay, 0s, 0s;
}

.sd-paneldynamic__panel-wrapper--fade-out {
    animation-direction: reverse;
    animation-timing-function: $reverse-ease-out;
    animation-duration: $pd-list-fade-out-duration, $pd-list-move-out-duration, $pd-list-move-out-duration;
    animation-delay: 0s, $pd-list-move-out-delay, $pd-list-move-out-delay;
    --animation-padding-bottom: calc(1 * var(--sd-base-padding));
}
