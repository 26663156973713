.sd-selectbase {
  border: none;
  margin: 0;
  padding: 0;
  //prevent comment to resize over question area
  min-inline-size: 0;
  min-width: 0;
}

.sd-selectbase--row {
  border: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: calcSize(4);
}

.sd-selectbase--multi-column {
  display: flex;
  flex: 1 1 0px;
}

.sd-selectbase__label {
  display: inline-flex;
  position: relative;
  gap: calcSize(1);
  vertical-align: top;
  max-width: 100%;
}

.sd-selectbase__column {
  vertical-align: top;
}

.sd-selectbase__column {
  display: block;
  box-sizing: border-box;
  flex: 1 1 0px;
  min-width: 0;
}

.sd-selectbase__column:not(:last-child) {
  padding-right: calcSize(2);
}

// Copied from stylesmanager
.sd-selectbase__column.sv-q-column-1 {
  width: 100%;
}