.sd-context-btn {
  background-color: $background;
  padding: calcSize(1.5);
  border-radius: calcSize(12.5);
  border: none;
  outline: none;

  .sv-svg-icon {
    margin: 0;
  }

  svg {
    @include useEditorFontSize;
    display: block;
    width: multiply(1.5, $font-editorfont-size);
    height: multiply(1.5, $font-editorfont-size);
  }

  use {
    fill: $foreground-light;
    transition: fill $transition-duration;
  }

  cursor: pointer;
}

.sd-context-btn:hover,
.sd-context-btn:focus {
  use {
    fill: $primary;
  }

  &.sd-context-btn--negative {
    use {
      fill: $red;
    }
  }
}

.sd-context-btn:disabled {
  opacity: 0.25;
}