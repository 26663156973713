.sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  position: sticky;
  left: calcSize(-3);
  margin-left: calcSize(-3);
  z-index: 12;
}

.sd-matrixdynamic__footer:first-child {
  padding-bottom: calcSize(2);
}

.sd-matrixdynamic__footer {
  padding-top: calcSize(2);
}

.sd-action.sd-matrixdynamic__remove-btn {
  opacity: 0.5;
}

.sd-action.sd-matrixdynamic__remove-btn .sd-action__icon {
  @include useEditorFontSize;
  width: multiply(1.5, $font-editorfont-size);
  height: multiply(1.5, $font-editorfont-size);
}

.sd-matrixdynamic__btn {
  appearance: none;
  background: transparent;
  border: none;
  line-height: calcLineHeight(1.5);
  font-size: calcFontSize(1);
  font-family: $font-family;
  font-weight: 600;
  padding: calcSize(1) 0;
}

.sd-matrixdynamic__drag-element {
  padding: calcSize(2);

  &:hover {
    background-color: $background;
  }

  &:after {
    content: " ";
    display: block;
    height: calcSize(0.5);
    width: calcSize(2);
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: calcSize(1.25);
  }
}

.sd-matrixdynamic__placeholder {
  .sd-matrixdynamic__add-btn {
    margin-left: 0;
  }
}

.sd-drag-element__svg {
  width: calcSize(2);
  height: calcSize(2);
  display: block;

  use {
    fill: $foreground-light;
  }
}

@keyframes borderAnimation {
  from {
    border-width: 0px;
  }
  to {
    border-width: 8px;
  }
}
@keyframes paddingAnimation {
  from {
    padding-top: 0;
    padding-bottom: 0;
  }
  to {
    padding-top: 24px;
    padding-bottom: 32px;
  }
}

@keyframes empty {
  from {
  }
  to {
  }
}
.sd-table__row--fade-out,
.sd-table__row--fade-in {
  animation-name: empty;
  animation-fill-mode: forwards;
  --fade-in-animation-duration: calc(#{$matrix-row-fade-in-duration} + #{$matrix-row-fade-in-delay});
  animation-duration: max(var(--fade-in-animation-duration), #{$matrix-row-move-in-duration});
  & > td {
    animation-name: borderAnimation;
    animation-timing-function: $ease-out;
    animation-duration: $matrix-row-move-in-duration;
    animation-fill-mode: forwards;
    & > div {
      animation-name: fadeIn, moveInWithOverflow;
      opacity: 0;
      animation-timing-function: $ease-out;
      animation-fill-mode: forwards;
      animation-duration: $matrix-row-fade-in-duration, $matrix-row-move-in-duration;
      animation-delay: $matrix-row-fade-in-delay, 0s;
    }
  }
}
.sd-table__row--fade-out {
  animation-direction: reverse;
  --move-out-animation-duration: calc(#{$matrix-row-move-out-duration} + #{$matrix-row-move-out-delay});
  animation-duration: max(var(--move-out-animation-duration), #{$matrix-row-fade-out-duration});
  & > td {
    animation-duration: $matrix-row-move-out-duration;
    animation-delay: $matrix-row-move-out-delay;
    animation-direction: reverse;
    animation-timing-function: $reverse-ease-out;
    & > div {
      animation-direction: reverse;
      animation-timing-function: $reverse-ease-out;
      animation-duration: $matrix-row-fade-out-duration, $matrix-row-move-out-duration;
      animation-delay: 0s, $matrix-row-move-out-delay;
    }
  }
}
.sd-table__row--detail {
  &.sd-table__row--fade-in,
  &.sd-table__row--fade-out {
    & > td {
      animation-name: borderAnimation, paddingAnimation;
      animation-duration: $matrix-row-move-in-duration;
      animation-fill-mode: forwards;
    }
  }

  &.sd-table__row--fade-in {
    --fade-in-animation-duration: calc(#{$matrix-detail-row-fade-in-duration} + #{$matrix-detail-row-fade-in-delay});
    animation-duration: max(var(--fade-in-animation-duration), #{$matrix-detail-row-move-in-duration});
    & > td {
      animation-timing-function: $ease-out;
      animation-duration: $matrix-detail-row-move-in-duration;
      & > div {
        animation-duration: $matrix-detail-row-fade-in-duration, $matrix-detail-row-move-in-duration;
        animation-delay: $matrix-detail-row-fade-in-delay, 0s;
      }
    }
  }
  &.sd-table__row--fade-out {
    --move-out-animation-duration: calc(#{$matrix-detail-row-move-out-duration} + #{$matrix-detail-row-move-out-delay});
    animation-duration: max(var(--move-out-animation-duration), #{$matrix-detail-row-fade-out-duration});
    & > td {
      animation-timing-function: $reverse-ease-out;
      animation-duration: $matrix-detail-row-move-out-duration;
      animation-delay: $matrix-detail-row-move-out-delay;
      animation-direction: reverse;
      & > div {
        animation-duration: $matrix-detail-row-fade-out-duration, $matrix-detail-row-move-out-duration;
        animation-delay: 0s, $matrix-detail-row-move-out-delay;
      }
    }
  }
}
